.centerPagination {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.centerPagination .pagination {
  margin: 0;
}

.inline {
  display: inline-flex;
  width: 100%;
}
.inline > input {
  margin-right: 10px;
}

.payed_tr {
  background: rgba(76, 175, 80, 0.3);
}

.due {
  background: #de6565;
}


/* Tooltips on Reminders Page */

.custom-tt {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.custom-tt .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tt:hover .tooltiptext {
  visibility: visible;
}

.custom-tt .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}


/* End Tooltips */


/* VBOX Orders List Customization */

.vboxorder-list-item {
  height: 60px;
}

.vboxorders-switch {
  position: absolute;
  top: 15px;
}

.vboxorders-list-text {
  position: absolute;
  top: 18px;
  left: 70px;
}

.vboxorders-orderprogress-dots-container {
  position: absolute;
  right: 10px;
  top: 20px;
}
