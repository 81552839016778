// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";

.horiscrollline {
  margin-bottom: 30px;
  border-bottom: 3px solid grey;
  padding-bottom: 10px;
  width: 100%;
}

.horiScrollMenu {
  overflow: hidden;
  white-space: nowrap;
}

.horiScrollMenuItems {
  width: 90%;
}

.horiScrollMenuButton {
  margin: 10px;
}

.horiScrollMenuItem {
  margin: 0;
  padding: 0;
}

.horiScrollMenuItem img {
  max-height: 200px;
  max-width: 80%;
  margin: 5px;
}

.flex {
  display: flex;
}
